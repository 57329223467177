import { useReservedEventStore } from '~/store/pinia/reserved-event'
import { useChannelStore } from '~/store/pinia/channel'
import { useGuardCalendarStore } from '~/store/pinia/guard-calendar'
import { useAppStatusGuardStore } from '~/store/pinia/app-status-guard'
import { useUnreadCountStore } from '~/store/pinia/unread-count'
import { useAppStatusStore } from '~/store/pinia/app-status'
import { useNoticeStore } from '~/store/pinia/notice'
import { useUnreadCountManagerStore } from '~/store/pinia/unread-count-manager'
import type { F21017Params } from '~~/types/F21017Params'

import * as Sentry from '@sentry/browser'
import { useStudentStore } from '~/store/pinia/student'

export async function storeSettingManager () {
  resetStore()
  // 未対応メッセージ取得処理
  useUnreadCountManagerStore().loadUnreadMessageCount()
  // ログイン情報取得
  await useAppStatusStore().loadInitAppStatus()

  // Studentデータ
  const studentParams: F21017Params = {
    schoolYear: '',
    week: '',
    status: '',
  }
  await useStudentStore().getStudentDatas(studentParams)
  // Noticeデータ
  await useNoticeStore().loadNoticeDatas()
}

export function resetStore() {
  useUnreadCountManagerStore().resetUnreadMessageCount()
  useAppStatusStore().resetAppStatus()
  useStudentStore().resetStudentList()
  useNoticeStore().resetNoticeList()
}

// 保護者用Store設定(Home画面用)
export async function storeSettingGuard(stuId: string) {
  // マナミルチャンネル
  useChannelStore().resetChannelList()
  useChannelStore().loadChannelList()
  // 生徒選択画面／切り替えモーダルを経由した場合selectedStuIdは設定されている
  if (useAppStatusGuardStore().getSelectedChild().userId == '') {
    await useAppStatusGuardStore().loadChildren().then(() => {
      // stuIdはプッシュ通知タップからログインした場合にのみ設定されており、undefinedの場合は単一生徒が紐づく保護者のログイン
      let selectedChild
      if(stuId != undefined) {
        const filteredChild = useAppStatusGuardStore().getChildren().filter(child => child.userId == stuId)[0]
        selectedChild = filteredChild != undefined ? filteredChild : useAppStatusGuardStore().getChildren()[0]
      } else {
        selectedChild = useAppStatusGuardStore().getChildren()[0]
      }
      useAppStatusGuardStore().setSelectedChild(selectedChild)
    }).catch((error) => {
      // Sentryにエラーを送信
      Sentry.captureException(error)
    })
  }
}

// 保護者用Storeリセット
export function resetStoreGuard() {
  useAppStatusGuardStore().resetAppStatusGuard()
  useChannelStore().resetChannelList()
  useUnreadCountStore().resetUnreadCount()
  useReservedEventStore().resetReservedEvents()
  useGuardCalendarStore().resetCalendarDataList()
}
